<template>
    <div class="page">
        <table id="table" class="pure_table" border="1">
            <tr>
                <th
                    class="title"
                    colspan="20"
                    contenteditable
                    :class="{ editable: isEdit }"
                    @blur="refresh($event, 'salary_header')"
                >
                    {{ salary_header }}工资单（单位：元）
                </th>
            </tr>
            <tr class="head">
                <th rowspan="2">姓名</th>
                <th rowspan="2">岗位</th>
                <th rowspan="2">出勤天数</th>
                <th rowspan="2">加班时数</th>
                <th colspan="6">应发</th>
                <th colspan="6">应扣</th>
                <th
                    rowspan="2"
                    contenteditable
                    :class="{ editable: isEdit }"
                    @blur="refresh($event, 'salary_tpl_text', 'tax')"
                >
                    税点
                </th>
                <th
                    rowspan="2"
                    contenteditable
                    :class="{ editable: isEdit }"
                    @blur="
                        refresh(
                            $event,
                            'salary_tpl_text',
                            'deduct_social_security'
                        )
                    "
                >
                    {{
                        tableHeader.deduct_social_security ||
                        "单位须承担社保费部分"
                    }}
                </th>
                <th
                    rowspan="2"
                    contenteditable
                    :class="{ editable: isEdit }"
                    @blur="
                        refresh(
                            $event,
                            'salary_tpl_text',
                            'deduct_accumulation_fund'
                        )
                    "
                >
                    {{
                        tableHeader.deduct_accumulation_fund ||
                        "单位须承担住房公积金部分"
                    }}
                </th>
                <th rowspan="2">实收</th>
            </tr>
            <tr class="head">
                <th>基本工资</th>
                <th>加班工资</th>
                <th
                    contenteditable
                    :class="{ editable: isEdit }"
                    @blur="refresh($event, 'salary_tpl_text', 'grant_1')"
                >
                    {{ tableHeader.grant_1 || "绩效奖金" }}
                </th>
                <th
                    contenteditable
                    :class="{ editable: isEdit }"
                    @blur="refresh($event, 'salary_tpl_text', 'grant_2')"
                >
                    {{ tableHeader.grant_2 || "高温津贴" }}
                </th>
                <th
                    contenteditable
                    :class="{ editable: isEdit }"
                    @blur="refresh($event, 'salary_tpl_text', 'grant_other')"
                >
                    {{ tableHeader.grant_other || "其他" }}
                </th>
                <th>小计</th>
                <th
                    contenteditable
                    :class="{ editable: isEdit }"
                    @blur="refresh($event, 'salary_tpl_text', 'deduct_1')"
                >
                    {{ tableHeader.deduct_1 || "社保" }}
                </th>
                <th
                    contenteditable
                    :class="{ editable: isEdit }"
                    @blur="refresh($event, 'salary_tpl_text', 'deduct_2')"
                >
                    {{ tableHeader.deduct_2 || "公积金" }}
                </th>
                <th
                    contenteditable
                    :class="{ editable: isEdit }"
                    @blur="refresh($event, 'salary_tpl_text', 'deduct_3')"
                >
                    {{ tableHeader.deduct_3 || "水电" }}
                </th>
                <th
                    contenteditable
                    :class="{ editable: isEdit }"
                    @blur="refresh($event, 'salary_tpl_text', 'deduct_4')"
                >
                    {{ tableHeader.deduct_4 || "借支" }}
                </th>
                <th
                    contenteditable
                    :class="{ editable: isEdit }"
                    @blur="refresh($event, 'salary_tpl_text', 'deduct_other')"
                >
                    {{ tableHeader.deduct_other || "其他" }}
                </th>

                <th>小计</th>
            </tr>
            <tr class="tr flat">
                <!-- :class="{ editable: isEdit }"
                    contenteditable
                    @input="handelInput($event, 'real_name')" -->
                <td>
                    {{ tableDetail.staff ? tableDetail.staff.real_name : "" }}
                </td>
                <td>
                    {{
                        tableDetail.staff
                            ? tableDetail.staff.department_data.station_name
                            : ""
                    }}
                </td>
                <td
                    :class="{ editable: isEdit }"
                    contenteditable
                    @blur="refresh($event, 'punch_day')"
                >
                    {{ tableDetail.punch_day }}
                </td>
                <td>{{ tableDetail.overtime }}</td>
                <td>{{ tableDetail.base_pay }}</td>
                <td>{{ tableDetail.overtime_pay }}</td>
                <td
                    :class="{ editable: isEdit }"
                    contenteditable
                    @blur="refresh($event, 'grant_1')"
                >
                    {{ tableDetail.grant_1 }}
                </td>
                <td
                    :class="{ editable: isEdit }"
                    contenteditable
                    @blur="refresh($event, 'grant_2')"
                >
                    {{ tableDetail.grant_2 }}
                </td>
                <td
                    :class="{ editable: isEdit }"
                    contenteditable
                    @blur="refresh($event, 'grant_other')"
                >
                    {{ tableDetail.grant_other }}
                </td>
                <td>{{ tableDetail.grant_subtotal }}</td>
                <td
                    :class="{ editable: isEdit }"
                    contenteditable
                    @blur="refresh($event, 'deduct_1')"
                >
                    {{ tableDetail.deduct_1 }}
                </td>
                <td
                    :class="{ editable: isEdit }"
                    contenteditable
                    @blur="refresh($event, 'deduct_2')"
                >
                    {{ tableDetail.deduct_2 }}
                </td>
                <td
                    :class="{ editable: isEdit }"
                    contenteditable
                    @blur="refresh($event, 'deduct_3')"
                >
                    {{ tableDetail.deduct_3 }}
                </td>
                <td
                    :class="{ editable: isEdit }"
                    contenteditable
                    @blur="refresh($event, 'deduct_4')"
                >
                    {{ tableDetail.deduct_4 }}
                </td>

                <td
                    :class="{ editable: isEdit }"
                    contenteditable
                    @blur="refresh($event, 'deduct_other')"
                >
                    {{ tableDetail.deduct_other }}
                </td>
                <td>{{ tableDetail.deduct_subtotal }}</td>
                <td>{{ tableDetail.tax }}</td>
                <td>{{ tableDetail.deduct_social_security }}</td>
                <td>{{ tableDetail.deduct_accumulation_fund }}</td>
                <td>{{ tableDetail.actual_salary }}</td>
            </tr>
            <tr>
                <td class="sign" colspan="20">员工签收：</td>
            </tr>
        </table>

        <table id="table" class="pure_table" border="1">
            <tr>
                <th
                    class="title"
                    colspan="20"
                    contenteditable
                    :class="{ editable: isEdit }"
                    @blur="refresh($event, 'salary_header')"
                >
                    {{salary_header}}预支经济补偿金（单位：元）
                </th>
            </tr>
            <tr class="head">
                <th>姓名</th>
                <th>岗位</th>
                <th>实收</th>
            </tr>
            <tr class="flat">
                <td>
                    {{ tableDetail.staff ? tableDetail.staff.real_name : "" }}
                </td>
                <td>
                    {{
                        tableDetail.staff
                            ? tableDetail.staff.department_data.station_name
                            : ""
                    }}
                </td>
                <td>{{ tableDetail.pre_salary }}</td>
            </tr>
            <tr>
                <td class="sign" colspan="3">备注：</td>
            </tr>
            <tr>
                <td class="sign" colspan="20">员工签收：</td>
            </tr>
        </table>

        <div class="btns">
            <div class="custom_button no_select" @click="submit">确认</div>
        </div>
    </div>
</template>

<script>
import printTable from "print-js";
import { mapActions } from "vuex";

let tableDetail = {};

export default {
    data() {
        return {
            values: {},
            tableHeader: {},
            tableDetail: {},
            isEdit: true,
            salary_header: '',
        };
    },
    props: ["id"],
    created() {
        this.values.id = this.id;
        this.getSalaryAuditHeader()
            .then((res) => {
                console.log("头部", res);
                this.tableHeader = res.data.data.detail;
            })
            .catch((e) => {
                console.error(e);
            });

        this.getSalarySplitDetail({ id: this.id })
            .then((res) => {
                console.log(res);
                this.salary_header = res.data.data.salary_header;
                tableDetail = res.data.data.detail;
                this.tableDetail = JSON.parse(JSON.stringify(tableDetail));
                this.values = {
                    id: this.id,
                    ...res.data.data.detail,
                };
            })
            .catch((e) => {
                console.error(e);
            });
    },
    mounted() {
        // this.print();
    },
    methods: {
        ...mapActions("pay", [
            "getSalaryAuditHeader",
            "getSalarySplitDetail",
            "postSalarySplitDetail",
        ]),

        submit() {
            let loading = this.$loading();
            this.postSalarySplitDetail({ ...this.values, is_form: true })
                .then((res) => {
                    this.$message({
                        type: "success",
                        message: res.data.msg,
                    });
                    loading.close();
                    this.$router.back();
                })
                .catch((e) => {
                    loading.close();
                    this.$message({
                        type: "error",
                        message: e.msg,
                    });
                });
        },

        refresh(e, name, name2) {
            e.target.innerHTML = e.target.innerHTML
                .replace(/<[^>]+>/g, "")
                .trim();
            if (name2) this.values.salary_tpl_text[name2] = e.target.innerHTML;
            else this.values[name] = e.target.innerHTML;
            console.log(this.values);

            this.postSalarySplitDetail(this.values)
                .then((res) => {
                    console.log(res);
                    this.values = { id: this.id, ...res.data.data.detail };
                    this.tableDetail = {
                        ...JSON.parse(JSON.stringify(this.tableDetail)),
                        ...this.values,
                    };
                })
                .catch((e) => {
                    console.error(e);
                    this.$message({
                        type: "error",
                        message: "表中存在错误信息！",
                    });
                });
        },

        print() {
            printTable({
                printable: "table",
                type: "html",
                maxWidth: "100%",
                targetStyles: ["*"],
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import url(./css/table.css);

.page {
    padding: 20px;
}

.btns {
    display: flex;
    margin-top: 25px;

    > div:nth-child(1) {
        margin-right: 10px;
    }
}

.editable {
    background-color: #f5c7cb;
}
</style>
